import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <div id="hero">
        <Hero roundedHeaderButton={true} />
      </div>
      <div id="features">
        <Features
          subheading={<Subheading>Felsefemiz</Subheading>}
          heading={
            <>
              Harika <HighlightedText>Hizmetlerimiz</HighlightedText> Var.
            </>
          }
        />
      </div>
      <div id="mainFeature">
        <MainFeature
          subheading={<Subheading>Kaliteli İş </Subheading>}
          imageSrc={heroScreenshotImageSrc}
          imageBorder={true}
          imageDecoratorBlob={true}
        />
      </div>
      <div id="values">
        <MainFeature2
          subheading={<Subheading>Değerlerimiz</Subheading>}
          heading={
            <>
              İlkelerimize <HighlightedText>Daima</HighlightedText> Bağlıyız
            </>
          }
          imageSrc={prototypeIllustrationImageSrc}
          showDecoratorBlob={false}
          features={[
            {
              Icon: MoneyIcon,
              title: "Uygun Fiyatlı",
              description:
                "Sizlere sektör standartlarına uygun en iyi fiyatı sunmayı taahhüt ediyoruz.",
              iconContainerCss: tw`bg-green-300 text-green-800`,
            },
            {
              Icon: BriefcaseIcon,
              title: "Profesyonellik",
              description:
                "Tüm çözümlerimizin profesyonel tasarımcılar tarafından tasarlandığını ve oluşturulduğunu garanti ediyoruz.",
              iconContainerCss: tw`bg-red-300 text-red-800`,
            },
          ]}
        />
      </div>
      <div id="faq">
        <FAQ
          subheading={<Subheading>SSS</Subheading>}
          heading={
            <>
              <HighlightedText>Sorularınız</HighlightedText> mı var?
            </>
          }
          faqs={[
            {
              question: "Şirketiniz hangi sektörlere hizmet veriyor?",
              answer:
                "Bizmut olarak, sağlık, finans, perakende, eğitim ve üretim gibi çeşitli sektörlere yazılım çözümleri sunuyoruz.",
            },
            {
              question: "Özelleştirilmiş yazılım çözümleri sunuyor musunuz?",
              answer:
                "Evet, işletmenizin özel ihtiyaçlarına uygun özelleştirilmiş yazılım çözümleri geliştiriyoruz.",
            },
            {
              question: "Destek ve bakım hizmetleri sağlıyor musunuz?",
              answer:
                "Evet, tüm projelerimiz için kapsamlı destek ve bakım hizmetleri sunuyoruz.",
            },
            {
              question: "Yazılım geliştirme süreciniz nasıl işliyor?",
              answer:
                "Yazılım geliştirme sürecimiz analiz, tasarım, geliştirme, test ve bakım aşamalarından oluşmaktadır.",
            },
            {
              question: "Güvenlik çözümleriniz neleri kapsıyor?",
              answer:
                "Siber güvenlik, veri koruma ve ağ güvenliği gibi çeşitli güvenlik çözümleri sunuyoruz.",
            },
            {
              question: "Yazılım çözümleriniz hangi teknolojileri kullanıyor?",
              answer:
                "Modern teknolojiler ve en iyi uygulamaları kullanarak yazılım çözümleri geliştiriyoruz, örneğin Java, .NET, React, Angular ve daha fazlası.",
            },
          ]}
        />
      </div>
      <Footer />
    </AnimationRevealPage>
  );
};
